<template>
  <div >
    <!--<TitleComponent class="primary--text text-uppercase text-center" :title="`Maps`"/>-->
    <PageHeader pagetitle="FULL DISCLOSURE" :img="img" />   
    <!--<v-container :class="$vuetify.breakpoint.lgAndUp ? 'small-container': ''">-->
    
  <v-row>
    <v-col
      class="d-flex child-flex"
      cols="12"
    >
      <v-card
        :width="$vuetify.breakpoint.mdAndUp ? 400 : 175"
        elevation="10"
      >
      <v-navigation-drawer
        permanent
        :width="$vuetify.breakpoint.mdAndUp ? 400 : 175"
      >
          

          <v-list
            nav
          >
            
          <v-list-item-group  active-class="border" color="indigo"> 
            <template  v-for="(itemcat,i) in this.TableCategoryData" >
              <v-divider :key="i" v-show="i !== 0"> </v-divider>
                <v-list-item 
                  link 
                 
                  @click=getSelectedCategory(itemcat.Name)
                  dark
                  :key="i"
                >
                
                  <v-list-item-icon>
                    <v-icon color="blue">mdi-{{itemcat.Icon}}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="blue--text text--darken-2">{{itemcat.Name}}</v-list-item-title>
                  <v-divider inset></v-divider>
              </v-list-item>
              <v-divider :key="i" v-show="i !== 0"> </v-divider>
            </template>
            </v-list-item-group>  
          </v-list>
      </v-navigation-drawer>
      </v-card> 

      <v-container>

        <v-data-table
          :headers="headers"
          :items="this.TableData"
          :items-per-page="15"
          class="elevation-1"
          dense
        >
                
          <template #item.Url="{ item }">
              <a target="_blank" :href="$api_url+item.Url">
                  {{ item.Name }}
              </a>
          </template>
        </v-data-table>   

      </v-container>
    </v-col>
  </v-row>
 
  <Footer v-if="this.$route.name !== 'Home'"> </Footer>
  </div>
</template>

<script>

import axios from 'axios' 
//import TitleComponent from '@/components/TitleComponent'
import Footer from '@/components/Footer'
import PageHeader from '@/components/PageHeader'


export default {

  name : 'FullDisclosure',
  components: {
    //TitleComponent,
    Footer,
    PageHeader
   
  },
  //directives: {
  //  swiper: directive
  //},
  data () {
    return {
      show: false,
      img : 'https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg',
      headers:[],
      items:[],
      TableData:[],
      TableCategoryData:[],
      SelectedCategory:'',
     
    }

    },

    watch: {
      // whenever question changes, this function will run
      //SelectedCategory: function () {
       // alert(this.SelectedCategory)
       // this.TableData=[];
       // this.TableData=this.getAllRecords(`/getAllRecords/fulldisclosure_details/where FullDisclosure='`+this.SelectedCategory+`'/Name asc`,`fulldisclosure_details`);
       // console.log(this.TableData)
      //},

    },
    mounted(){
      //alert(this.items);
      //this.TableData=this.getAllRecords(`/getAllRecords/fulldisclosure_details/where ID > 0 /Name asc`,`fulldisclosure_details`);
      this.headers=[ 
        { text: 'DOCUMENT NAME',
          value: 'Url',
          align: 'start',
         },
      ]
      this.TableCategoryData=this.getAllRecords(`/getAllRecords/fulldisclosurecategory/where ID > 0 /Name asc`,`fulldisclosurecategory`);
    
    
    },  

    updated(){
      
     
    },

    
    methods:{

      UpdateSelectedCategory(SelectedCategory){
        //alert(SelectedCategory);
        this.SelectedCategory=SelectedCategory
      },

      getSelectedCategory(SelectedCategory){
        console.log(SelectedCategory)
        this.getAllRecords(`/getAllRecords/fulldisclosure/where FullDisclosureCategory='`+SelectedCategory+`'/Name asc`,`fulldisclosure`);
        console.log(this.TableData)
      },

      toggleshow(item){
        item.Show=!item.Show
      },
      formatData(value){
        //alert(value);!isNaN(Date.parse(value)) 
        //alert(value)
        var findchar=value.toString().search('-');
        if ( !isNaN(parseFloat(value)) && (findchar < 0)) { 
            
            return Number(value).toLocaleString(undefined,{minimumFractionDigits:2,maximumFractionDigits:2});
        }
        else if( !isNaN(Date.parse(new Date(value))) ){
            
            return this.formatDate(value);
        }
        else{
            return value
        }
      },
      getAllRecords:function(query,table){
      axios.get( this.$api_url+query,{
          
        params: {
              
          }
      })
      .then(response => {
          //alert(Object.keys(response.data).length);
          if (Object.keys(response.data).length > 0) {
              //alert(response.data);
              if(table=='fulldisclosure'){
                this.TableData=response.data;
                //alert(this.TableData) 
               
              }
              else if(table=='fulldisclosurecategory'){
                this.TableCategoryData=response.data
              }
              
            }
      })
      .catch(error => { console.log(error)})
    },  
  }
}

</script>

<style lang="scss" >
  
.border {
  border: 2px dashed orange;
}

</style>